import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {SuiModalModule, SuiPopupModule, SuiSelectModule} from '@angular-ex/semantic-ui';

import {SharedFormsModule} from '../forms/shared-forms.module';
import {InfoModalComponent} from './info-modal/info-modal.component';
import {AlertModalComponent} from './alert-modal/alert-modal.component';
import {RegisterModalComponent} from './register-modal/register-modal.component';
import {UserRegisterFormComponent} from './register-modal/user-register-form/user-register-form.component';

@NgModule({
  declarations: [
    AlertModalComponent,
    InfoModalComponent,
    RegisterModalComponent,
    UserRegisterFormComponent,
  ],
    imports: [
        CommonModule,
        SuiModalModule,
        SharedFormsModule,
        ReactiveFormsModule,
        SuiSelectModule,
        SuiPopupModule,
    ],
  providers: [
  ],
  exports: [
    AlertModalComponent,
    InfoModalComponent,
    RegisterModalComponent,
  ]
})
export class ModalsModule {
}
