import {Router} from '@angular/router';
import {Component, HostListener, OnInit} from '@angular/core';

import {ModalSize, SuiModalService} from '@angular-ex/semantic-ui';

import {KeycloakService} from 'keycloak-angular';
import {User} from './core/models/users/users.model';
import {UsersService} from './core/services/users/users.service';
import {RegisterModal} from './shared/modals/register-modal/register-modal.component';
import {KeycloakProfile} from 'keycloak-js';
import {environment} from '../environments/environment';
import {ToastService} from "./core/services/toast/toast.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'fit-app';

  constructor(
    private usersService: UsersService,
    private keycloakService: KeycloakService,
    public modalService: SuiModalService,
    private router: Router,
    private toastService: ToastService,
  ) {
  }

  async ngOnInit() {
    await this.initUser();
  }

  private async initUser() {
    const profile = await this.keycloakService.loadUserProfile().catch(err => (console.error(err), undefined));
    if (!profile) {
      setTimeout(() => this.keycloakService.login(), 500);
      return;
    }
    const user = await this.usersService.getCurrentUser();
    // Check if it's a new user
    if (!user || !user.abonnement || !user.institution) {
      this.completeRegistration(profile, user);
    }
  }

  private completeRegistration(kcUser: KeycloakProfile, user: User) {
    if (user) {
      this.openRegisterModal();
    } else {
      // create user profile
      this.usersService.createOne({
        _id: '',
        kcId: kcUser.id || '',
        email: kcUser.email || '',
        firstname: kcUser.firstName || '',
        lastname: kcUser.lastName || '',
        abonnement: {
          email: kcUser.email || '',
          topics: [],
          subjectAreas: [],
          days: [],
        },
      }).then(() => {
        // finish registration
        this.openRegisterModal();
      });
    }
  }

  // Local Development: Press Ctrl-Shift-R to open Register Modal
  @HostListener('document:keydown.control.shift.r', ['$event'])
  public openRegisterModal($event?: KeyboardEvent) {
    if ($event && environment.production) {
      return;
    }
    this.modalService
      .open(new RegisterModal('FIT-Newsletter abonnieren', ModalSize.Large))
      .onApprove(() => {
        // Info modal successfull created
        this.toastService.success('Newsletter aboniert', `Der FIT-Newsletter wurde erfolgreich abonniert
        und wird Ihnen an den gewünschten Tagen zugestellt. Natürlich können Sie den Newsletter auch jederzeit abbestellen.`)
        this.initUser();
        this.router.navigate(['/']);
      });
  }
}
