@if (user) {
  <div class="header">{{ modal.context.title }}</div>
  <div class="content scrolling">
    <h3 class="ui header">Profil Einstellungen: </h3>
    <app-user-profile-form #profileForm [editUser]="user" [simple]="true"></app-user-profile-form>
    <h3 class="ui header">Newsletter Einstellungen: </h3>
    <app-user-newsletter-form #newsletterForm [editUser]="user" [simple]="true"></app-user-newsletter-form>
  </div>
  <div class="actions">
    <button id="cancel-logout-button" class="ui secondary button" (click)="keycloakService.logout()">
      Abbruch & Logout
    </button>
    <button id="save-button" class="ui primary button" [disabled]="profileForm.userForm.invalid || newsletterForm.newsletterForm.invalid" (click)="finishRegistration(profileForm.submit(), newsletterForm.submit())">
      Speichern
    </button>
  </div>
}
