import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';

import {SuiDropdownModule} from '@angular-ex/semantic-ui';

import {KeycloakService} from 'keycloak-angular';
import {FooterComponent} from './footer/footer.component';
import {SharedComponentsModule} from '../components/shared-components.module';
import {NavigationBarComponent} from './navigation-bar/navigation-bar.component';

@NgModule({
  declarations: [
    NavigationBarComponent,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedComponentsModule,
    SuiDropdownModule,
  ],
  providers: [
    KeycloakService,
  ],
  exports: [
    NavigationBarComponent,
    FooterComponent,
  ],
})
export class LayoutModule {
}
