import {Component} from '@angular/core';
import {ComponentModalConfig, ModalSize, SuiModal} from '@angular-ex/semantic-ui';

interface IInfoModalContext {
  title: string;
  info: string;
}

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.css']
})
export class InfoModalComponent {

  constructor(public modal: SuiModal<IInfoModalContext, void, void>) {
  }
}

export class InfoModal extends ComponentModalConfig<IInfoModalContext, void, void> {
  constructor(title: string, info: string, size = ModalSize.Small) {
    super(InfoModalComponent, {title, info});

    this.isClosable = false;
    this.transitionDuration = 200;
    this.size = size;
  }
}
