<div id="page-container">
  <div id="content-wrap">
    <app-navigation-bar></app-navigation-bar>
    <router-outlet></router-outlet>
  </div>
  <footer id="footer">
    <app-footer></app-footer>
  </footer>
</div>
<app-toast-list></app-toast-list>
