import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import * as Sentry from '@sentry/angular';
import {browserProfilingIntegration, browserTracingIntegration} from '@sentry/angular';

Sentry.init({
  enabled: !!environment.sentry.dsn,
  integrations: [browserTracingIntegration(), browserProfilingIntegration()],
  dsn: environment.sentry.dsn,
  environment: environment.sentry.environment,
  release: environment.sentry.release,
  tracesSampleRate: environment.sentry.tracesSampleRate,
  tracePropagationTargets: ['http://localhost:3000/api', 'https://fit.uni-kassel.de/api'],
  profilesSampleRate: environment.sentry.profilesSampleRate,
  beforeSend: async (event, hint) => {
    hint.attachments = [
      {
        filename: "screenshot.jpg",
        data: await captureScreen(),
        contentType: 'image/jpeg',
      },
    ];
    return event;
  },
});

async function captureScreen(): Promise<Uint8Array> {
  const html2canvas = await import('html2canvas');
  const canvas = await html2canvas.default(document.body, {
    scale: 1,
    ignoreElements: element => element instanceof SVGElement,
  });
  const blob: Blob = await new Promise((resolve, reject) => canvas.toBlob(
    blob => blob ? resolve(blob) : reject(),
    'image/jpeg',
    0.5, // go for lower quality. A 4k screenshot is about 100KiB this way and text is still readable.
  ));
  const buffer = await blob.arrayBuffer();
  return new Uint8Array(buffer);
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
