<div class="ui grid">
  <div class="one wide column"></div>
  <div class="fourteen wide column">
    <h1 class="ui header">{{ formTitle }}</h1>
    <form class="ui form" [formGroup]="userForm" (ngSubmit)="submit()">

      <!--PERSONAL DATA-->
      <h3 class="ui header">Persönliche Daten:</h3>
      <!--GENDER-->
      <div class=field>
        <label for="genderSelect">Geschlecht:</label>
        <sui-select id="genderSelect" class="selection" [options]="genders" formControlName="gender" #genderSelect>
          <sui-select-option *ngFor="let option of genderSelect.filteredOptions" [value]="option">
          </sui-select-option>
        </sui-select>
      </div>
      <!--TITLE-->
      <div class="field">
        <label for="titleInput">Titel:</label>
        <input id="titleInput" formControlName="title" type="text" name="titel" placeholder="Titel">
      </div>
      <!--STATUS GROUP-->
      <div class=field>
        <label for="statusGroupSelect">Statusgruppe:*</label>
        <sui-select id="statusGroupSelect" class="selection" [options]="statusGroups" formControlName="statusGroup"
                    #statusGroupSelect>
          <sui-select-option *ngFor="let option of statusGroupSelect.filteredOptions" [value]="option">
          </sui-select-option>
        </sui-select>
      </div>
      <!--INSTITUTION-->
      <div *ngIf="!institutions || institutions.length < 1">
        <span style="color: red">Zur E-Mail-Domain gibt es keine Institutionen. Bitte wenden Sie sich an <a
          href="mailto:fit@uni-kassel.de">fit&#64;uni-kassel.de</a></span>
      </div>

      <div *ngIf="institutions && institutions.length === 1" class="field" id="institution">
        <label for="institution">Institution:*</label>
        {{ institutions[0].name }}
        <i class="circle info icon" suiPopup
           [popupTemplate]="popupTemplate"
           popupPlacement="right"
           popupWidth="wide"
           popupTrigger="outsideClick"
        ></i>
      </div>

      <div *ngIf="institutions && institutions.length > 1" class="field">
        <label for="institutionSelect">Institution:*</label>
        <sui-select id="institutionSelect" class="selection" [options]="institutions" labelField="name" valueField="_id"
                    [isSearchable]="true"
                    (selectedOptionChange)="institutionSelectChange($event)" formControlName="institution"
                    #institutionSelect>
          <sui-select-option *ngFor="let option of institutionSelect.filteredOptions" [value]="option">
          </sui-select-option>
        </sui-select>
        <div
          *ngIf="user.abonnement?.email?.includes('uni-goettingen.de')">
          <span style="color: red;"> Für die Universität Göttingen wird zwischen "Universität Göttingen" und der "Universitätsmedizin Göttingen unterschieden.
            Daher würden wir Sie bitten zu kontrollieren, ob Sie die für Sie zutreffende Institution gewählt haben!</span>
        </div>
      </div>
      <!--DEPARTMENT-->
      <div class="field" *ngIf="userForm.controls.institution.value">
        <label for="departmentSelect">Department:</label>
        <sui-select id="departmentSelect" class="selection" [options]="user.institution?.departments || []"
                    formControlName="department" #departmentSelect>
          <sui-select-option *ngFor="let option of departmentSelect.filteredOptions" [value]="option">
          </sui-select-option>
        </sui-select>
      </div>
    </form>
    <!--NEWSLETTER DATA-->
    <h3 class="ui header">Newsletter Daten:</h3>
    <form class="ui form" [formGroup]="newsletterForm" (ngSubmit)="submit()">
      <!--TOPICS-->
      <div class="field">
        <label for="topicSelect">Ich möchte Informationen zu folgenden Kategorien erhalten (Eine Mehrfachauswahl ist
          möglich!):*</label>
        <sui-multi-select id="topicSelect" class="selection" [options]="topics" labelField="name" valueField="_id"
                          formControlName="topics" #topicsMultiSelect>
          <ng-container *ngFor="let kind of TOPIC_KINDS">
            <div class="header">
              <i class="list icon"></i>
              {{ kind }}
            </div>
            <div class="divider"></div>
            <ng-container *ngFor="let option of topicsMultiSelect.filteredOptions">
              <sui-select-option *ngIf="option['kind'] === kind" [value]="option"></sui-select-option>
            </ng-container>
          </ng-container>
        </sui-multi-select>
      </div>
      <!--SUBJECTAREAS-->
      <div class="field">
        <label for="subjectAreaSelect">Ich möchte aus den Kategorien nur Artikel zu folgenden Fachrichtungen erhalten
          (Eine Mehrfachauswahl ist möglich!):*</label>
        <sui-multi-select id="subjectAreaSelect" class="selection" [options]="subjectAreas" labelField="name"
                          valueField="_id"
                          formControlName="subjectAreas" #subjectAreasMultiSelect>
          <sui-select-option *ngFor="let option of subjectAreasMultiSelect.filteredOptions" [value]="option">
          </sui-select-option>
        </sui-multi-select>
      </div>
      <!--WEEKDAYS FOR MAILING-->
      <div class="field">
        <label for="weekdaySelect">An folgenden Wochentagen möchte ich den Newsletter erhalten (Eine Mehrfachauswahl ist
          möglich!):*</label>
        <sui-multi-select id="weekdaySelect" class="selection" [options]="weekdays" labelField="name" valueField="value"
                          formControlName="days" #daysMultiSelect>
          <sui-select-option *ngFor="let option of daysMultiSelect.filteredOptions" [value]="option">
          </sui-select-option>
        </sui-multi-select>
      </div>

      <!--SUBMIT FORM-->
      <button class="ui primary button" type="submit" [disabled]="userForm.invalid">Speichern</button>
    </form>
  </div>
  <div class="one wide column"></div>
</div>

<ng-template #popupTemplate>
  <span>Wenden Sie sich zur Änderung Ihrer Institution bitte an <a href="mailto:fit@uni-kassel.de">fit&#64;uni-kassel.de</a>!</span>
</ng-template>
