<div class="ui inverted menu" style="border-radius: 0">
  <!--HOME-->
  <a class="item" routerLink="/home" [routerLinkActive]="['active']">
    Home
  </a>
  <!--TOPICS-->
  <a class="item" *ngIf="userRoles.includes(clientRoles.ACCESS_CATALOG)" routerLink="/topics"
     [routerLinkActive]="['active']">
    Kategorien
  </a>
  <!--ARTICLES-->
  <a class="item" *ngIf="userRoles.includes(clientRoles.ACCESS_CATALOG)" routerLink="/articles"
     [routerLinkActive]="['active']">
    Artikel
  </a>
  <!--PUBLIZIEREN-->
  <div *ngIf="userRoles.includes(clientRoles.ACCESS_PUBLISH)" class="ui pointing dropdown link item" suiDropdown>
    <span class="text">Publizieren</span>
    <i class="dropdown icon"></i>
    <div class="menu" suiDropdownMenu>
      <a class="item" routerLink="/author/articles">Artikel</a>
      <a class="item" routerLink="/author/internalMessages">Interne Mitteilungen</a>
    </div>
  </div>
  <!--ADMINISTRATION AND SETTINGS-->
  <div class="ui inverted right menu">
    <!--SUBADMIN-->
    <div *ngIf="userRoles.includes(clientRoles.ACCESS_SUBADMIN)"
         class="ui pointing dropdown link item" suiDropdown>
      <span class="text">Administration</span>
      <i class="dropdown icon"></i>
      <div class="menu" suiDropdownMenu>
        <div class="item" routerLink="/subadmin/institution">Institution verwalten</div>
        <div class="item" routerLink="/subadmin/internalMessages">Interne Mitteilungen verwalten</div>
      </div>
    </div>
    <!--SUBADMINSTATISTICS-->
    <a class="item" *ngIf="userRoles.includes(clientRoles.ACCESS_SUBADMIN)" routerLink="/statistics/subadmin/users"
       [queryParams]="{sortBy: 'newUsers'}" [routerLinkActive]="['active']">
      Eigene Statistiken
    </a>
    <!--STATISTICS-->
    <div *ngIf="userRoles.includes(clientRoles.ACCESS_ADMIN)" class="ui pointing dropdown link item" suiDropdown>
      <span class="text">Alle Statistiken</span>
      <i class="dropdown icon"></i>
      <div class="menu" suiDropdownMenu>
        <a class="item" routerLink="/statistics/articles" [queryParams]="{sortBy: 'newArticles'}">Artikel</a>
        <a class="item" routerLink="/statistics/users" [queryParams]="{sortBy: 'byInstitutions'}">Nutzer</a>
      </div>
    </div>
    <!--ADMIN-->
    <div *ngIf="userRoles.includes(clientRoles.ACCESS_ADMIN)" class="ui pointing dropdown link item" suiDropdown>
      <span class="text">Adminbereich</span>
      <i class="dropdown icon"></i>
      <div class="menu" suiDropdownMenu>
        <a class="item" routerLink="/admin/topics">Kategorien</a>
        <a class="item" routerLink="/admin/articles">Artikel</a>
        <a class="item" routerLink="/admin/subjectareas">Fachrichtungen</a>
        <a class="item" routerLink="/admin/institutions">Institutionen</a>
        <a class="item" routerLink="/admin/users">Nutzer</a>
        <a class="item" routerLink="/admin/contacts">Kontaktpersonen</a>
        <a class="item" routerLink="/admin/mailing">Admin-Mail</a>
      </div>
    </div>

    <a class="item" *ngIf="userRoles.includes(clientRoles.ACCESS_SETTINGS)"
       [routerLink]="['/settings/profile']" [routerLinkActive]="['active']">
      Einstellungen
    </a>
    <a class="item"
       [routerLink]="['/contact']" [routerLinkActive]="['active']">
      Kontakt
    </a>
    <div class="item">
      @if (userRoles.length) {
        <button id="logout-button" class="ui primary button" (click)="keycloakService.logout()">Logout</button>
      } @else {
        <button id="login-button" class="ui primary button" (click)="keycloakService.login()">Login</button>
      }
    </div>
  </div>
</div>
