import localeDe from '@angular/common/locales/de';
import {registerLocaleData} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import localeDeExtra from '@angular/common/locales/extra/de';
import {APP_INITIALIZER, ErrorHandler, importProvidersFrom, LOCALE_ID, NgModule} from '@angular/core';
import {createErrorHandler, TraceService} from '@sentry/angular';

import {SuiModule} from '@angular-ex/semantic-ui';

import {ThemeService} from 'ng2-charts';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AdminModule} from './modules/admin/admin.module';
import {ModalsModule} from './shared/modals/modals.module';
import {LayoutModule} from './shared/layout/layout.module';
import {ChartsModule} from './shared/charts/charts.module';
import {AuthorModule} from './modules/author/author.module';
import {TopicsModule} from './modules/topics/topics.module';
import {ContactModule} from './modules/contact/contact.module';
import {PrivacyModule} from './modules/privacy/privacy.module';
import {SettingsModule} from './modules/settings/settings.module';
import {SubadminModule} from './modules/subadmin/subadmin.module';
import {ArticlesModule} from './modules/articles/articles.module';
import {DirectivesModule} from './shared/directives/directives.module';
import {StatisticsModule} from './modules/statistics/statistics.module';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {environment} from '../environments/environment';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {ToastListComponent} from './core/components/toast-list/toast-list.component';
import {Base64Pipe} from './shared/pipes/base64.pipe';
import {Router} from '@angular/router';

function initializeKeycloak(keycloak: KeycloakService) {
  return async () => await keycloak.init({
    config: environment.auth,
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri:
        window.location.origin + '/assets/silent-check-sso.html',
    },
    enableBearerInterceptor: true,
    loadUserProfileAtStartUp: true,
  }).catch(err => console.error('Keycloak init failed', err));
}

registerLocaleData(localeDe, localeDeExtra);


@NgModule({
  declarations: [
    AppComponent,
    ToastListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AdminModule,
    AuthorModule,
    SettingsModule,
    SubadminModule,
    ArticlesModule,
    LayoutModule,
    DirectivesModule,
    StatisticsModule,
    SuiModule,
    ContactModule,
    PrivacyModule,
    TopicsModule,
    ModalsModule,
    ChartsModule,
    Base64Pipe,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'de'},
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    importProvidersFrom(KeycloakAngularModule),
    provideHttpClient(withInterceptorsFromDi()),
    ThemeService,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
