import { Component } from '@angular/core';
import {Message} from '../../services/toast/message';
import {ToastService} from '../../services/toast/toast.service';

@Component({
  selector: 'app-toast-list',
  templateUrl: './toast-list.component.html',
  styleUrl: './toast-list.component.css'
})
export class ToastListComponent {
  protected readonly navigator = navigator;

  messages: Message[];

  constructor(
    toastService: ToastService,
  ) {
    this.messages = toastService.messages;
  }

  cancelHide(message: Message) {
    clearTimeout(message.timeoutId);
  }
}
