import {Component, OnInit} from '@angular/core';
import {ComponentModalConfig, ModalSize, SuiModal} from '@angular-ex/semantic-ui';

import {KeycloakService} from 'keycloak-angular';
import {User} from '../../../core/models/users/users.model';
import {UsersService} from '../../../core/services/users/users.service';
import {UserFormValues} from '../../forms/user-profile-form/user-profile-form.component';
import {UserNewsletterFormValues} from '../../forms/user-newsletter-form/user-newsletter-form.component';

interface IRegisterModalContext {
  title: string;
  question: string;
}

@Component({
  selector: 'app-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.css']
})
export class RegisterModalComponent implements OnInit {
  user!: User;

  constructor(
    public modal: SuiModal<IRegisterModalContext, void, void>,
    private usersService: UsersService,
    public keycloakService: KeycloakService,
  ) {
  }

  ngOnInit() {
    this.usersService.getCurrentUser().then((user: User) => {
      this.user = user;
      this.user.abonnement ||= {
        email: user.email,
        topics: [],
        subjectAreas: [],
        days: [],
      };
      // We must set this because the field is hidden in the newsletter form and it would be invalid otherwise.
      this.user.abonnement.email ||= user.email;
    });
  }

  finishRegistration(profile: UserFormValues, newsletter: UserNewsletterFormValues) {
    const {receiveInternalMessages, ...rest} = newsletter;
    this.usersService.updateOne(this.user._id, {
      ...profile,
      receiveInternalMessages,
      abonnement: {
        ...this.user.abonnement,
        ...rest,
        email: this.user.email,
        // last mail send one week ago
        lastMailSend: new Date(Date.now() - 7 * 24 * 60 * 60000),
      },
    }).then(() => {
      this.modal.approve();
    });
  }
}

// Helper Class to auto configure modal
export class RegisterModal extends ComponentModalConfig<IRegisterModalContext, void, void> {
  constructor(title: string, question: string, size = ModalSize.Small) {
    super(RegisterModalComponent, {title, question});

    this.isClosable = false;
    this.transitionDuration = 200;
    this.size = size;
  }
}
