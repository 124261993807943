<div style="position: fixed; top: 1rem; right: 1rem">
  @for (message of messages; track message) {
    <sui-message
      (mouseenter)="cancelHide(message)"
      [class]="message.type"
      [isDismissable]="true"
      (dismiss)="messages.splice($index)"
    >
      <h5 class="header">
        {{ message.title }}
      </h5>
      <p>
        {{ message.message }}
      </p>
    </sui-message>
  }
</div>
