import {Component, OnInit} from '@angular/core';

import {KeycloakService} from 'keycloak-angular';
import {clients} from '../../../core/constants/clients.config';
import {clientRoles} from '../../../core/constants/client-roles.config';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css'],
})
export class NavigationBarComponent implements OnInit {
  // constants
  public clientRoles = clientRoles;
  public userRoles: string[] = [];

  constructor(
    public keycloakService: KeycloakService,
  ) {
  }

  ngOnInit() {
    this.userRoles = this.keycloakService.getUserRoles(false, clients.API);
  }
}
