<div class="footer">
  <div class="ui grid">
    <div class="row">
      <div class="one wide column"></div>
      <div class="fourteen wide column">
        <div class="item">
          <div class="content">
            <div class="description">
              <p style="color:#FFFFFF">
                Die Informationen zur Forschungsförderung stammen aus öffentlich zugänglichen Quellen. <a class="link" href="https://www.uni-kassel.de/go/fit" target="_blank">FIT für die
                Wissenschaft</a>
                übernimmt keine Gewähr für die Richtigkeit und Vollständigkeit der Informationen. Weitere Informationen
                zum
                Datenschutz können sie
                <a class="link" routerLink="/privacy">hier</a> abrufen.
                Hinweise und Rückfragen senden Sie bitte an: <a class="link" href="mailto:fit@uni-kassel.de">fit&#64;uni-kassel.de</a>
            </div>
          </div>
        </div>
      </div>
      <div class="one wide column"></div>
    </div>
  </div>
</div>
