import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {
  AllowedMailDomainsValidatorDirective
} from './allowed-mail-domains-validator/allowed-mail-domains-validator.directive';

@NgModule({
  declarations: [
    AllowedMailDomainsValidatorDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    AllowedMailDomainsValidatorDirective,
  ]
})
export class DirectivesModule {
}
