import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import {User} from '../../../../core/models/users/users.model';
import {Topic} from '../../../../core/models/topics/topics.model';
import {Institution} from '../../../../core/models/institutions/institutions.model';
import {GENDERS, STATUS_GROUPS, TOPIC_KINDS, WEEKDAYS} from '../../../../core/constants';
import {InstitutionsService} from '../../../../core/services/institutions/institutions.service';


@Component({
  selector: 'app-user-register-form',
  templateUrl: './user-register-form.component.html',
  styleUrls: ['./user-register-form.component.css']
})
export class UserRegisterFormComponent implements OnInit {
  @Output() registerUserData: EventEmitter<any> = new EventEmitter();

  @Input() subjectAreas: Topic[] = [];
  @Input() topics: Topic[] = [];
  @Input() institutions: Institution[] = [];
  @Input() formTitle!: string;
  @Input() user!: User;

  public genders: string[] = GENDERS;
  public statusGroups: string[] = STATUS_GROUPS;
  public weekdays = WEEKDAYS;
  public TOPIC_KINDS = TOPIC_KINDS;

  userForm!: UntypedFormGroup;
  newsletterForm!: UntypedFormGroup;
  subjectsAreasFormArray!: UntypedFormControl;
  topicsFormArray!: UntypedFormControl;
  daysFormArray!: UntypedFormControl;

  constructor(
    private fb: UntypedFormBuilder,
    private institutionsService: InstitutionsService,
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.buildSubjectAreasFormArray();
    this.buildTopicsFormArray();
    this.buildDaysFormArray();

    this.newsletterForm = this.fb.group({
      email: [''],
      subjectAreas: this.subjectsAreasFormArray,
      topics: this.topicsFormArray,
      days: this.daysFormArray,
    });

    this.userForm = this.fb.group({
      gender: [''],
      title: [''],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      statusGroup: ['', Validators.required],
      institution: ['', Validators.required],
      department: [''],
      abonnement: this.newsletterForm,
    });
  }

  updateValues(user: User) {
    if (user.abonnement) {
      this.newsletterForm.patchValue(user.abonnement);
    }

    this.userForm.patchValue(user);
  }

  submit() {
    if (this.userForm.valid && this.newsletterForm.valid) {
      this.userForm.controls.institution.setValue(this.user.institution);
      this.registerUserData.emit(this.userForm.value);
    }
  }

  institutionSelectChange(event: string) {
    this.institutionsService.getOne(event).then((res: Institution) => {
      this.user.institution = res;
    });
    // reset department if a new institution is selected
    this.userForm.controls.department.setValue(null);
  }

  buildDaysFormArray() {
    this.daysFormArray = new UntypedFormControl('', Validators.required);
  }

  buildTopicsFormArray() {
    this.topicsFormArray = new UntypedFormControl('', Validators.required);
  }

  buildSubjectAreasFormArray() {
    this.subjectsAreasFormArray = new UntypedFormControl('', Validators.required);
  }
}
