import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from './core/auth/auth-guard';
import {clientRoles} from './core/constants/client-roles.config';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    children: [
      {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'author',
        loadChildren: () => import('./modules/author/author.module').then(m => m.AuthorModule),
        canActivate: [AuthGuard],
        data: {
          expectedRoles: [
            clientRoles.IS_AUTHOR,
          ]
        },
      },
      {
        path: 'topics',
        loadChildren: () => import('./modules/topics/topics.module').then(m => m.TopicsModule),
        canActivate: [AuthGuard],
        data: {
          expectedRoles: [
            clientRoles.IS_USER,
          ]
        },
      },
      {
        path: 'articles',
        loadChildren: () => import('./modules/articles/articles.module').then(m => m.ArticlesModule),
        canActivate: [AuthGuard],
        data: {
          expectedRoles: [
            clientRoles.IS_USER,
          ]
        },
      },
      {
        path: 'subadmin',
        loadChildren: () => import('./modules/subadmin/subadmin.module').then(m => m.SubadminModule),
        canActivate: [AuthGuard],
        data: {
          expectedRoles: [
            clientRoles.IS_SUBADMIN,
          ]
        },
      },
      {
        path: 'statistics',
        loadChildren: () => import('./modules/statistics/statistics.module').then(m => m.StatisticsModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard],
        data: {
          expectedRoles: [
            clientRoles.IS_ADMIN,
          ]
        },
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'contact',
        loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule)
      },
      {
        path: 'privacy',
        loadChildren: () => import('./modules/privacy/privacy.module').then(m => m.PrivacyModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
